.image_section .image:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($primary, 0.25);
}

.image_section {
  position: relative;
  transition: all 0.3s;
  display: flex;
  .image-section__body {
    width: 100%;
    max-width: $maxWidth;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    color: $imageSection_color;
  }
  .image_gradient {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    &.image_gradient-top {
      top: 0;
      z-index: 0;
      background-image: linear-gradient(180deg, $imageSection_gradient, transparent);
    }
    &.image_gradient-bottom {
      z-index: 0;
      bottom: 0;
      background-image: linear-gradient(0deg, $imageSection_gradient, transparent);
    }
  }
  .image {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
    filter: brightness(0.6);
  }
}
