.imageCards_section-body {
    display: flex;

    .imageCards-item {
        display: flex;
        flex: 1;
        opacity: 0;
        animation: simplyOpacity 0.5s forwards;
        //Image Section
        > .imageCards-item__image_section {
            flex: 1;
            cursor: pointer;
            position: relative;
            > .image {
                height: 500px;
                width: 100%;
                background-size: 210%;
                background-repeat: no-repeat;
                background-position: center;
                transition: all 0.4s ease-out;
                &:before {
                    @include box(100%, 100%, false, rgba(#000, 0.5));
                    opacity: 0;
                    transition: all 0.3s;
                }
            }
            > .imageCards_section-back {
                opacity: 0;
                transition: all 0.3s;
                color: #fff;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 15px;
            }
            &:hover {
                > .image {
                    transition: all 0.2s ease-out;
                    background-size: 260%;
                    &:before {
                        opacity: 1;
                    }
                }
            }
            .imageCards_section-title {
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: 16px;
                color: #fff;
                padding: 15px;
                opacity: 0;
                transition: all 0.3s;
                > .imageCards_section-title__name {
                    font-size: 25px;
                    line-height: 1;
                    margin-bottom: 6px;
                }
                > .imageCards_section-title__position {
                    font-size: 14px;
                    color: #aaa;
                }
            }
        }
        &:not(.active) {
            .imageCards-item__image_section {
                position: relative;
                &:hover {
                    .imageCards_section-title {
                        transition: all 0.3s 0.2s;
                        opacity: 1;
                    }
                }
            }
        }

        //Data Section

        > .imageCards-item__data_section {
            flex: 1;
            display: none;
            overflow: hidden;
        }

        &.active {
            > .imageCards-item__data_section {
                flex: 2;
                display: block;
                color: #fff;
                > div {
                    padding-left: 15px;
                    > .imageCards_section-position {
                        opacity: 0;
                        animation: simplyOpacity 1s 0.5s ease-out forwards;
                        font-size: 14px;
                        margin-bottom: 0px;
                        color: #ddd;
                    }
                    > .imageCards_section-name {
                        opacity: 0;
                        animation: fade-in 0.6s ease-out forwards;
                        font-size: 25px;
                        margin-bottom: 20px;
                    }
                    > .imageCards_section-description {
                        opacity: 0;
                        animation: simplyOpacity 1s 1s forwards;
                        font-size: 20px;
                    }
                }
            }

            .imageCards-item__image_section:hover {
                .imageCards_section-back {
                    transition: 0.3s 0.2s;
                    opacity: 1;
                }
            }
        }
        &.hidden {
            display: none;
        }
    }
}

.imageCards_section-description {
    white-space: pre-line;
}
