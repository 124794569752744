.alt-header {
  color: green;
}

.about_text_container {
  i.fa {
    transition: all 0.3s;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0px;
    top: calc(50% - 7px);
  }
  .about__person {
    transition: all 0.3s;
    // height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    .degrees {
      margin-right: 5px;
      font-size: 16px;
    }
    .personPosition {
      opacity: 0;
      font-size: 14px;
      transition: all 0.5s;
      margin-left: -10px;
      margin-bottom: -10px;
      position: absolute;
      top: -20px;
      white-space: nowrap;
    }
    .personName {
      transition: all 0.5s;
      font-size: 24px;
      opacity: 0.5;
      position: relative;
      line-height: 1;
      padding-bottom: 7px;
      &:before {
        transition: all 0.3s;
        @include box(1px, 0%, true, #fff) {
          position: absolute;
          bottom: 0;
          left: 50%;
        }
      }
    }

    &.active {
      .personName {
        transition: all 0.3s;
        opacity: 1;
      }
      .personPosition {
        margin-left: 0;
        transition: all 0.3s;
        opacity: 0.8;
      }
    }
    &.active {
      :before {
        width: 100%;
        left: 0%;
      }
    }
  }
}
