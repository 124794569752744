@media only screen and (max-width: 766px) {
    // BASE
    .contact_text_container {
        [class*="col"]:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    .box {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    //NAVBAR AND HAMBURGER MENU
    #header .header-body nav {
        position: fixed;
        opacity: 0;
        pointer-events: none;
        background-color: $primary;
        width: 80vw;
        max-width: 250px;
        min-width: 200px;
        height: 100vh;
        transform: translateX(100%);
        display: flex;
        flex-direction: column;
        padding: 80px 30px 30px 30px;
        transition: all 0.3s;
        right: 0;
        z-index: 10;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        &.show {
            transform: translateX(0%);
            pointer-events: all;
            opacity: 1;
        }
        > a {
            margin-right: 0 !important;
            line-height: 40px;
        }
    }

    .hamburger_menu {
        pointer-events: all;
        opacity: 1;
    }

    //Black bg after open Hamburger
    .menu_open #header:after {
        opacity: 1;
    }

    //OFFER PAGE

    .offerIconList {
        flex-wrap: wrap;
        [class*="item_"] {
            svg {
                width: 6rem;
                height: 6rem;
            }
        }
    }
    .offer_text_container .image {
        margin-top: 30px;
    }
    .list_with_numbers__item:not(:first-child) {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
    .list_with_numbers__item.col-md-12 {
        margin-top: 20px;
    }
}

//ABOUT PAGE
@media only screen and (min-width: 767px) {
    .about__person:hover {
        .personName {
            transition: all 0.3s;
            opacity: 1;
        }
        .personPosition {
            margin-left: 0;
            transition: all 0.3s;
            opacity: 0.8;
        }
    }

    .about_text_container {
        .about__persons_row {
            flex-wrap: nowrap;
            [class*="col"] > div {
                width: fit-content;
            }
            [class*="col"]:not(:last-child) {
                border-right: 1px solid rgba(255, 255, 255, 0.5);
            }
            [class*="col"]:not(:first-child):not(:last-child) {
                > div {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            [class*="col"]:last-child {
                border-left: 1px solid rgba(255, 255, 255, 0.5);

                > div {
                    margin-left: auto;
                }
            }
            .about__person_0 {
                min-width: 220px;
            }

            .about__person_2 {
                min-width: 217px;
            }
            .about__person_3 {
                flex: 1;
            }
            .about__person_4 {
                min-width: 170px;
            }
        }
    }
}
@media only screen and (max-width: 766px) {
    #about-page {
        + .box {
            padding-top: 20px;
        }
    }
    .about_text_container {
        padding-top: 25px;
        .about__person {
        }
        .about__persons_row {
            [class*="col"]:not(:first-child) {
                margin-top: 20px;
            }
            &:not(.noneSelected) {
                .about__person:not(.active) {
                    opacity: 0;
                    height: 0px;
                    margin-top: 0px;
                    animation: none;
                    pointer-events: none;
                }
            }
            .about__person.active {
                .personPosition,
                .personName {
                    margin-left: 30px;
                }
                i.fa {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }
}

@media only screen and (max-width: 766px) {
    .contact_text_container {
        > .row {
            > .col-md-6 {
                .contactCol {
                    margin-top: 30px;
                }
            }
        }
    }
}
