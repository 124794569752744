@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";
@import "./mixins.scss";
@import "./animations.scss";
@import "./base.scss";
@import "./header.scss";
@import "./image_section.scss";
@import "./imageCards_section.scss";
@import "./about-page.scss";
@import "./offer-page.scss";
@import "./contact-page.scss";

.title {
  font-weight: 700;
  font-size: 40px;
  width: 100%;
  max-width: 700px;
  overflow: hidden;
  > span {
    color: $titleColor;
    animation: fade-in-from-down 1s forwards;
    opacity: 0;
    display: inline-block;
  }
}

.description {
  width: 100%;
  max-width: 700px;
  color: $descriptionColor;
  font-size: 20px;
  font-weight: 300;
  margin-top: 20px;
}

.card {
  background-color: $primary;
  padding: 15px;
  height: 300px;
  font-size: 20px;
  font-weight: 300;
  border: none;
  &:not(:first-child):not(:last-child) {
    margin: 7.5px;
  }
  &:first-child {
    margin-left: 15px;
    margin-right: 7.5px;
  }
  &:last-child {
    margin-left: 7.5px;
    margin-right: 15px;
  }
}

.card.main_card {
  background-color: $card_bg;
  color: $card_color;
}

.box {
  z-index: 100;
  padding: 40px 0;
  background-color: $box_color;
  background-image: linear-gradient(0, rgba(0, 0, 0, 0.25), transparent);
  position: relative;
  > *:first-child:last-child {
    max-width: $maxWidth;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

//Wave

.wave_container {
  overflow: hidden;
  height: 300px;
  position: absolute;
  left: 0;
}
.wave {
  width: 100vw;
  z-index: -1;
}
.wave.wave--top {
  top: 50px;
  transform: translateY(-100%);
  > svg {
    width: 400%;
    transform: scaleY(0.1) translateX(0%);
    transform-origin: bottom;
    animation: wave_animation 4s 2s ease-in-out forwards;
  }
}
.wave.wave--bottom {
  overflow: hidden;
  width: 100vw;
  position: absolute;
  bottom: 50px;
  transform: translateY(100%) rotate(180deg);
  left: 0;
  > svg {
    width: 400%;
    transform: scaleY(0.1) translateX(0%);
    transform-origin: bottom;
    animation: wave_animation 4s 2s ease-in-out forwards;
  }
}

.box_width_fade {
  position: relative;
  background-color: $fadeBoxColor;
  padding: 40px 0;
  &:before {
    @include box(100px, 100%, true, $fadeBoxColor, transparent, 0) {
      position: absolute;
      top: -100px;
      z-index: -1;
    }
  }
  &:after {
    @include box(100px, 100%, true, $fadeBoxColor, transparent, 180deg) {
      z-index: -1;
      position: absolute;
      bottom: -100px;
    }
  }
}

.row .icon-wrapper {
  text-align: center;
  margin-bottom: 15px;
  > svg {
    margin-left: auto;
    margin-right: auto;
    height: 100px;
    width: 100px;
    background-color: #444;
    border-radius: 50%;
    padding: 15px;
  }
}
.row span {
  text-align: justify;
}

.main_card {
  padding: 30px;
  text-align: center;
  color: $main_card_color;
  div {
    text-align: justify;
    white-space: pre-line;
  }
  .fa {
    font-weight: normal !important;
    font-family: fontAwesome;
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    font-size: 40px;
    background-color: $cardIcon_bg;
    color: $cardIcon_color;
    border-radius: 50%;
    line-height: 80px;
    margin-bottom: 15px;

    > svg {
      fill: $primaryLight;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

footer {
  position: relative;
  width: 100vw;
  height: $footer_height;
  background-color: $primary;
  color: $footer_color;
  &:before {
    @include box(160px, 100%, true, #000, transparent, 0.5) {
      position: absolute;
      top: -160px;
    }
  }
  > svg {
    transform-origin: bottom;
    transform: scaleY(0.2);

    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
  }
  .footer-body {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > span {
      display: block;
      font-size: 12px;
    }
  }
}

.fade-in-from-top {
  animation: fade-in-from-top 1s forwards;
}

.box .button_arrow-down {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: none;
  // border: 1px solid #fff;
  // border-radius: 50%;
  color: #fff;
  left: calc(50% - 30px);
  top: -72px;
  z-index: 1000;
  &:hover {
    i {
      transform: translateY(10px);
    }
  }
  i {
    font-size: 30px;
    transition: all 0.3s;
  }
}

.btn-secondary {
  background-color: $primary;
  border-color: $primary;
  border-radius: 0;
  box-shadow: 0px 0px 8px rgba(#000, 0.10);
  &:hover {
    background-color: mix($primaryLight, #000, 70%);
    border-color: mix($primaryLight, #000, 70%);
  }
}


//Need to be on the end of file
@import "./rwd.scss";
