@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes big-fade-in {
    0% {
        opacity: 0;
        transform: translateX(-80%);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes simplyOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in-from-top {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade-in-from-down {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    50% {
        transform: translateY(55px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes wave_animation {
    0% {
        transform: scaleY(0.1) translateX(0%);
    }
    100% {
        transform: scaleY(0.1) translateX(-70%);
    }
}

@keyframes scaleUpRotate {
    0% {
        transform: scaleY(0), rotate(0);
    }
    100% {
        transform: scaleY(0.2);
    }
}

@keyframes scaleUp {
    0% {
        transform: translateY(-99%) scaleY(0);
        left: -150%;
        width: 200%;
    }
    100% {
        transform: translateY(-99%) scaleY(0.2);
        left: 0;
        width: 200%;
    }
}

@keyframes gradientLoading {
    0% {
        background-position: -300% 0%;
    }
    50% {
        background-position: 50% 0%;
    }
    100% {
        background-position: 300% 0%;
    }
}


.fade-in-from-top {
    animation: fade-in-from-top 1s forwards;
}