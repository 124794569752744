.offer_text_container,
.about_text_container,
.contact_text_container {
    color: #fff;
    white-space: pre-line;
    h1,
    h2,
    h3,
    h4 {
        margin: 15px 0;
    }
    > div {
        white-space: pre-line;
    }
}
.offer_text_container .image {
    height: 100%;
}
// .offer_text_container.with_image {
//     display: flex;
//     > div {
//         flex: 1;
//     }
//     > .image {
//         flex: 1;
//         background-size: 100%;
//         transition: all 0.6s ease-in-out;
//         background-origin: center center;
//         &:hover {
//             transition: all 0.2s;
//             background-size: 120%;
//         }
//     }
//     > :first-child {
//         margin-right: 15px;
//     }
//     > :last-child {
//         margin-left: 15px;
//     }
// }

.title_with_icon {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    > i {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 30px;
        width: 50px;
        height: 50px;
        margin-right: 15px;
        color: $primary;
        &:after {
            content: "";
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
}

.list_with_numbers {
    // display: flex;
    .list_with_numbers__item {
        // flex: 1;
        .list_with_numbers__item_number {
            display: flex;
            position: relative;
            color: $primary;
            width: 50px;
            height: 50px;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            font-size: 30px;
            font-weight: bold;
            &:after {
                content: "";
                display: block;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                top: 1px;
                left: 1px;
                z-index: -1;
            }
            margin-bottom: 15px;
        }
        > .list_with_numbers__item_text {
            text-align: justify;
        }
    }
}

.list_with_numbers {
    > .row {
        > .list_with_numbers__item {
            .animated {
                position: relative;
                &:before {
                    content: "";
                    display: block;
                    background-color: #ffffff3b;
                    width: calc(100% + 1.5rem);
                    height: 2px;
                    z-index: -1;
                    position: absolute;
                    top: 25px;
                    left: -0.75rem;
                }
            }
        }
    }
}

.offerIconList {
    display: flex;

    > div {
        flex: 1;
        .offerIconList_item {
            color: #fff;
            text-align: center;
            cursor: pointer;
            opacity: 0.6;
            transition: all 1s;
            &:hover {
                opacity: 1;
                transition: all 0.3s;
            }
            &.active {
                opacity: 1;
                .offerIcon {
                    transition: all 0.3s;
                    filter: drop-shadow(0 0px 5px rgba(255, 255, 255, 0.5));
                }
            }
            .offerIconList_item__icon {
                > img {
                    display: block;
                    width: 150px;
                    height: 150px;
                    margin-left: auto;
                    margin-right: auto;
                }
                > i {
                    display: flex;
                    width: 150px;
                    height: 150px;
                    background-color: #fff;
                    border-radius: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    color: $primary;
                    align-items: center;
                    justify-content: center;
                    font-size: 70px;
                }
            }
            .offerIconList_item__title {
                margin-top: 15px;
                user-select: none;
            }
        }
    }
}

.offerIcon {
    filter: drop-shadow(0 0px 0px rgba(255, 255, 255, 0));
}
