html,
body,
#app,
#header,
#app:before {
  min-width: 320px;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300;
  color: $fontColor;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $primary;
}
body.menu_open {
  overflow-y: scroll;
  overflow-x: hidden;
}

#app,
html,
body {
  max-width: 100vw;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

.center {
  text-align: center;
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.5s forwards ease-out;
}

body {
  #app {
    display: block;
  }
  &:before {
    content: "";
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10001;
    pointer-events: none;
    opacity: 0;
    transition: all 0.5s 0s ease-out;
  }
  &.js-loading {
    &:before {
      pointer-events: none;
      opacity: 1;
      transition: none;
    }
    #app {
      display: none;
    }
  }
}

.app_container {
  min-height: calc(100vh - #{$footer_height});
}

a.link {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  display: inline-block;
  filter: drop-shadow(0px 0px 0px rgba(#fff, 0));
  transition: all 0.6s ease-in-out;
  &:after {
    @include box(1px, 0%, false, #fff);
    opacity: 0;
    transition: all 0.6s ease-in-out;
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    filter: drop-shadow(0px 0px 2px rgba(#fff, 0.5));
    transition: all 0.2s;
    &:after {
      width: 100%;
      opacity: 1;
      transition: all 0.2s;
    }
  }
}

.row_with_padding {
  padding-left: 5%;
  padding-right: 5%;
}

.fa-hands-with-heart {
  position: relative;
  &:before {
    font-family: fontAwesome;
    content: "\f4c2";
    position: relative;
    top: 5px;
  }
  &:after {
    font-family: fontAwesome;
    content: "\f004";
    position: absolute;
    top: -8px;
    left: calc(50% - 16px);
    font-size: 0.75em;
    font-weight: 100;
  }
}

.section-title {
  display: block;
  margin-bottom: 15px;
  font-size: 25px;
  color: #fff;
}

.isLoading {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(90deg, transparent, #fff, transparent);
    animation: gradientLoading 10s infinite linear;
    background-size: 300% 100%;
    z-index: 2;
  }
}
