$maxWidth: 1540px;

// $primary: #3a3226;
$primary: #46382b;
$primaryLight: #7e664e;
// $primary: #18241a;
$secondary: #73d0f4;

$whiteColor: #f3eae0;

$fontColor: $primary;

$imageSection_color: $primary;
//Font colors

$titleColor: #fff;
$descriptionColor: #fff;
$fadeBoxColor: rgba($primary, 0.5);

$cardIcon_bg: #fff;
$cardIcon_color: $primaryLight;
$main_card_color: #fff;
$card_bg: #fff;
$box_color: $primaryLight;

$imageSection_gradient: rgba($primary, 0.3);
//Navbar

$navBar_bg: $primary;
$header_font_color: #fff;
$stickyHeader_font_color: #fff;
$header_height: 80px;


//Footer
$footer_height: 70px;
$footer_color: mix($primaryLight, #fff, 80%);
