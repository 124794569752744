.contact_text_container {
    > .row {
        > .col-md-6 {
            display: flex;
            flex-direction: column;
            .addressCol {
                animation: simplyOpacity 1s forwards;
                margin-bottom: 30px;
            }
            .contactCol {
                margin-top: auto;
                margin-bottom: 0;
                animation: simplyOpacity 1s 0.2s forwards;
                opacity: 0;
            }
        }
    }
}

.contactTable {
    margin-top: 15px;
    margin-bottom: 15px;
    tr {
        > td:nth-child(1) {
            padding-right: 15px;
            padding-bottom: 5px;
        }
        > td:nth-child(2) {
            white-space: nowrap;
            padding-bottom: 5px;
            padding-left: 15px;
        }
    }
}
