@mixin box(
  $height,
  $width: 100%,
  $isGradient: false,
  $bgColor: transparent,
  $bgColor2: transparent,
  $gradientDeg: 0deg
) {
  content: "";
  display: block;
  width: $width;
  height: $height;
  @if $isGradient {
    background-image: linear-gradient($gradientDeg, $bgColor, $bgColor2);
  } @else {
    background-color: $bgColor;
  }
  @content;
}
@mixin gradient($color, $color2, $height, $deg: 0px) {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $height;
  z-index: 0;
  background-image: linear-gradient($deg, $color, $color2);
  @content;
}