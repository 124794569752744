#header {
  height: $header_height;
  width: 100%;
  .header-body {
    display: flex;
    padding: 0 15px;
    padding-right: 37px;
    .header__logo {
      font-size: 25px;
      line-height: $header_height;
      color: #fff;
    }
    nav {
      flex: 1;
      text-align: right;
      a {
        color: #fff;
        line-height: $header_height;
        text-decoration: none;
        position: relative;
        padding: 10px 0;
        &:not(:last-child) {
          margin-right: 30px;
        }
        &:before {
          @include box(1px, 0%, false, #fff) {
            position: absolute;
            bottom: 0;
            left: 50%;
            transition: all 0.3s;
          }
        }
        &:hover,
        &.active {
          &:before {
            width: 100%;
            left: 0%;
          }
        }
      }
    }
  }
}

.header {
  animation: fade-in-from-top 1s 1s ease-out forwards;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  a {
    color: $header_font_color;
  }
}

#header.header__sticky {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-right: 15px;
  &:before {
    @include box(100%, 100%, false, $primary) {
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
      z-index: -1;
      opacity: 0;
    }
  }

  &.showStickyHeader:before {
    opacity: 1;
  }
  .header-body {
    .header__logo {
      color: $stickyHeader_font_color;
    }
    nav a {
      color: $stickyHeader_font_color;
    }
  }
}

.hamburger_menu {
  position: fixed;
  top: 21px;
  right: 27px;
  pointer-events: none;
  opacity: 0;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  z-index: 11;
  cursor: pointer;

  > span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s ease;
    opacity: 1;
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  > span:nth-child(1) {
    margin-top: auto;
  }
  > span:nth-child(2) {
    width: 80%;
  }
  > span:nth-child(3) {
    margin-bottom: auto;
  }

  &:hover {
    > span {
      width: 100%;
    }
  }
}
.menu_open .hamburger_menu {
  > span:nth-child(1) {
    transform: rotate(45deg) translate(10px, 10px);
    transform-origin: center center;
  }
  > span:nth-child(2) {
    width: 0%;
    opacity: 0;
    margin-left: auto;
    margin-right: auto;
  }
  > span:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -4px);
    transform-origin: center center;
  }
}

#header:after {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1;
}
